import "ant-design-vue/es/message/style/css";
import _message from "ant-design-vue/es/message";
import { PlusCircleOutlined, MinusCircleOutlined, PaperClipOutlined, ProfileOutlined, SolutionOutlined, PartitionOutlined, BankOutlined } from '@ant-design/icons-vue';
import { defineComponent, ref, reactive, computed, watch, onMounted } from 'vue';
import getFieldRules from '@/utils/fieldRules';
import uploadImgs from '@/views/dataManagement/components/uploadImgs.vue';
import { selectBillByParam, outboundUpdateToApproval, insertReviewing, updateOutboundApply, insertReturn, insertReturnConfirm, selectLocationByParam, selectApprovalItem } from '@/api/material';
import moment from 'moment';
import printJS from 'print-js';
import Cookies from "js-cookie";
export default defineComponent({
  components: {
    PlusCircleOutlined,
    MinusCircleOutlined,
    PaperClipOutlined,
    uploadImgs,
    ProfileOutlined,
    SolutionOutlined,
    PartitionOutlined,
    BankOutlined
  },
  props: {
    applyVisible: {
      type: Boolean,
      default: false
    },
    applyDetailData: {
      type: Object,
      default: () => {}
    }
  },
  emits: ['closeApplyVisible'],

  setup(props, context) {
    const formRef = ref();

    const closeApplyVisible = () => {
      // formRef.value.resetFields();
      context.emit('closeApplyVisible', {
        isClose: false,
        state: 1
      });
    };

    const clObjNew = ref([]);
    const formState = reactive({
      theme: "",
      materials: [{
        materialsName: undefined,
        materialsSpecs: undefined,
        materialsNum: undefined,
        supplierName: undefined,
        materialsNameData: [],
        supplierNameData: [],
        materialsSpecsData: [],
        materialsNumData: [],
        clObj: []
      }],
      purpose: '',
      useTime: undefined,
      // approvalProcedureDtos:[],
      approvalDtos: [],
      hyApproachApprovals: [],
      content: undefined,
      confirmMaterials: [],
      signContent: "",
      signFileurl: [],
      returnMaterials: [],
      returnFileurl: [],
      //
      returnName: '',
      returnPhone: ''
    });
    const formState1 = reactive({
      materials1: [{
        materialsName: undefined,
        materialsSpecs: undefined,
        materialsNum: undefined,
        supplierName: undefined,
        materialsNameData: [],
        supplierNameData: [],
        materialsSpecsData: [],
        materialsNumData: [],
        clObj: []
      }],
      content: '',
      itemId: null
    });
    const formState2 = reactive({
      materials1: [{
        materialsName: undefined,
        materialsSpecs: undefined,
        materialsNum: undefined,
        supplierName: undefined,
        outboundNum: undefined
      }]
    });
    watch(() => props.applyDetailData.data, val => {
      if (JSON.stringify(val) !== "{}") {
        formState.theme = props.applyDetailData.data.theme;
        formState.materials = props.applyDetailData.data.materials;
        formState.purpose = props.applyDetailData.data.purpose;
        formState.useTime = props.applyDetailData.data.useTime;
        formState.returnName = props.applyDetailData.data.returnName;
        formState.returnPhone = props.applyDetailData.data.returnPhone;
        formState1.materials1 = props.applyDetailData.data.materials;
        formState1.content = "";
        formState.itemId = props.applyDetailData.data.itemId;
        formState1.materials1.forEach(ixt => {
          let list = {
            supplierName: ixt.supplierName,
            materialsNum: ixt.materialsNum,
            isShowAdd: ixt.supplierName ? false : true,
            //库管员审批时没有供应商就显示可添加按钮
            outboundApplyId: ixt.outboundApplyId,
            materialsLocationList: [{
              materialsId: ixt.outboundApplyId,
              methodType: 3,
              materialsNum: null,
              physicsLocation: null
            }]
          };
          ixt.list = [list];
        }); // formState.approvalProcedureDtos = props.applyDetailData.data.approvalProcedureDtos

        formState.approvalDtos = props.applyDetailData.data.approvalDtos;
        formState.hyApproachApprovals = props.applyDetailData.data.hyApproachApprovals;
        formState.outConfirm = props.applyDetailData.data.outConfirm;
        formState.confirmMaterials = props.applyDetailData.data.confirmMaterials;
        formState.returnMaterials = props.applyDetailData.data.returnMaterials;
        formState.confirmApproval = props.applyDetailData.data.confirmApproval;
        formState.signFileurl = props.applyDetailData.data.signFileurl ? JSON.parse(props.applyDetailData.data.signFileurl) : [];
        formState.returnFileurl = props.applyDetailData.data.returnFileurl ? JSON.parse(props.applyDetailData.data.returnFileurl) : [];

        if (props.applyDetailData.state == 16) {
          formState2.materials1 = JSON.parse(JSON.stringify(props.applyDetailData.data.confirmMaterials));
          formState2.returnFileurl = props.applyDetailData.data.returnFileurl ? JSON.parse(props.applyDetailData.data.returnFileurl) : [];
          formState2.materials1.forEach(item => {
            if (item.supplierNames.length) {
              item.supplierNames.forEach(ixt => {
                ixt.materialsLocationList = [{
                  materialsId: item.outboundApplyId,
                  methodType: 4,
                  materialsNum: null,
                  physicsLocation: null
                }];
              });
            }
          });
        }
      }
    });
    const isDisabledForCl = computed(() => {
      if (props.applyDetailData.state == 1) {
        return true;
      } else if (props.applyDetailData.state == 3) {
        return true;
      } else if (props.applyDetailData.state == 11) {
        return true;
      } else if (props.applyDetailData.state == 14) {
        return true;
      } else if (props.applyDetailData.state == 15) {
        return true;
      } else if (props.applyDetailData.state == 12) {
        return true;
      } else if (props.applyDetailData.state == 2) {
        return false;
      } else if (props.applyDetailData.state == 16) {
        return true;
      }
    });
    const isDisabledForSp = computed(() => {
      if (props.applyDetailData.state == 1) {
        return true;
      } else if (props.applyDetailData.state == 3) {
        return true;
      } else if (props.applyDetailData.state == 11) {
        return false;
      } else if (props.applyDetailData.state == 14) {
        return true;
      } else if (props.applyDetailData.state == 15) {
        return true;
      } else if (props.applyDetailData.state == 12) {
        return true;
      } else if (props.applyDetailData.state == 2) {
        return true;
      } else if (props.applyDetailData.state == 16) {
        return true;
      }
    }); //库管确认

    const isDisabledForKu = computed(() => {
      if (props.applyDetailData.state == 11) {
        return false;
      } else {
        return true;
      }
    });
    const {
      signFileurl,
      checkName,
      checkPhone
    } = getFieldRules();
    const rules = {
      theme: [{
        required: true,
        message: '申请主题不能为空',
        trigger: 'blur'
      }, {
        pattern: /^[^\s]*$/,
        message: '禁止输入空格',
        trigger: 'blur'
      }],
      useTime: [{
        required: true,
        message: '进场时间不能为空',
        trigger: 'change',
        type: 'object'
      }],
      purpose: [{
        required: true,
        message: '材料用途不能为空',
        trigger: 'blur'
      }, {
        pattern: /^[^\s]*$/,
        message: '禁止输入空格',
        trigger: 'blur'
      }],
      signFileurl: [{
        required: true,
        validator: signFileurl,
        type: 'array',
        trigger: formState.signFileurl.length == 0
      }],
      returnFileurl: [{
        required: true,
        validator: signFileurl,
        type: 'array',
        trigger: formState.returnFileurl.length == 0
      }],
      itemId: [{
        required: true,
        message: '审批流程线不能为空',
        type: 'number',
        trigger: 'change'
      }]
    }; //添加材料

    const addMaterialInformation = () => {
      formState.materials.push({
        materialsName: undefined,
        materialsSpecs: undefined,
        materialsNum: undefined,
        supplierName: undefined,
        materialsNameData: [],
        supplierNameData: [],
        materialsSpecsData: [],
        maxNum: undefined,
        clObj: []
      });
    }; //删除材料


    const deleteMaterialInformation = index => {
      formState.materials.splice(index, 1);
    };

    const loading = ref(false); //提交

    const handleOk = () => {
      formRef.value.validate().then(async () => {
        // const clObj = ref([]);
        // clObj.value = JSON.parse(JSON.stringify(clObjNew.value));
        // let isOk = true
        // formState.materials.filter((item,index)=>{
        //   if(item.supplierName){
        //     clObj.value.filter((item1,index1)=>{
        //       if(item.materialsName===item1.materialsName&&item.supplierName===item1.supplierName&&item.materialsSpecs===item1.materialsSpecs){
        //         if(item1.inventoryNum >= item.materialsNum){
        //           item1.inventoryNum = item1.inventoryNum - item.materialsNum;
        //         }else{
        //           message.error("名称为"+item.materialsName+"、供应商为"+item.supplierName+"、规格为"+item.materialsSpecs+"的材料库存不足，请调整申请数量");
        //           isOk = false
        //           return;
        //         }
        //       }
        //     });
        //   }
        // });
        // formState.materials.filter((item,index)=>{
        //   if(item.supplierName==""||item.supplierName==null){
        //     let num = 0;
        //     for (var i = 0; i < clObj.value.length; i++) {
        //       if (item.materialsName===clObj.value[i].materialsName&&item.materialsSpecs===clObj.value[i].materialsSpecs) {
        //         num = num+clObj.value[i].inventoryNum;
        //         clObj.value.splice(i, 1);
        //         i--;
        //       }
        //     }
        //     if(num >= item.materialsNum){
        //       num = num - item.materialsNum;
        //       clObj.value.push({
        //         materialsName: item.materialsName,
        //         materialsSpecs: item.materialsSpecs,
        //         inventoryNum: num
        //       })
        //     }else{
        //       message.error("名称为"+item.materialsName+"、规格为"+item.materialsSpecs+"的材料库存不足，请调整申请数量");
        //       isOk = false
        //       return;
        //     }
        //   }
        // });
        // if(!isOk) {
        //   return
        // }
        const obj = {
          applyId: props.applyDetailData.data.applyId,
          theme: formState.theme,
          purpose: formState.purpose,
          useTime: formState.useTime.startOf('day').format('YYYY-MM-DD'),
          materials: formState.materials,
          itemId: formState.itemId
        };
        loading.value = true; // console.log('00000000',obj)

        let res = await updateOutboundApply(obj);

        if (res.code === 200) {
          _message.success(res.message);

          formRef.value.resetFields();
          context.emit('closeApplyVisible', {
            isClose: false,
            state: 2
          });
        } else {
          _message.error(res.message);
        }

        loading.value = false;
      }).catch(error => {
        console.log('error', error);
      });
    };

    const disabledDate = current => {
      return current && current <= moment().subtract(1, 'days');
    }; //材料名称查询
    // const clObj = ref([])


    const handleSearch = async (val, index) => {
      const param = {
        materialsName: val,
        supplierName: null
      };
      let res = await selectBillByParam(param);
      console.log(res);

      try {
        formState.materials[index].materialsNameData = [];

        if (res.data.length) {
          res.data.forEach(ixt => {
            formState.materials[index].materialsNameData.push({
              value: ixt.materialsName,
              text: ixt.materialsName
            });
          });
          uniqueJsonArray(formState.materials[index].materialsNameData, 'text'); // console.log(formState.materials[index].materialsNameData)
        } else {
          formState.materials[index].materialsNameData.push({
            value: '无数据',
            text: '无数据',
            disabled: true
          });
        }

        clObjNew.value = res.data;
        formState.materials[index].clObj = res.data;
      } catch (error) {
        console.log(error);
      }
    };

    const uniqueJsonArray = (array, key) => {
      for (var i = 0; i < array.length; i++) {
        for (var j = array.length - 1; j > 0; j--) {
          if (array[i][key] == array[j][key] && i != j) {
            array.splice(j, 1);
          }
        }
      }

      return array;
    }; //供应商


    const handleFocusForSup = (materialsName, materialsSpecs, index) => {
      formState.materials[index].supplierNameData = [];
      formState.materials[index].clObj.forEach(item => {
        if (item.materialsName == materialsName && item.materialsSpecs == materialsSpecs) {
          formState.materials[index].supplierNameData.push({
            value: item.supplierName,
            text: item.supplierName
          });
        }

        uniqueJsonArray(formState.materials[index].supplierNameData, 'text');
      });
    }; //规格


    const handleFocusForSp = (val, index) => {
      formState.materials[index].materialsSpecsData = [];
      formState.materials[index].clObj.forEach(item => {
        if (item.materialsName == val) {
          formState.materials[index].materialsSpecsData.push({
            value: item.materialsSpecs,
            text: item.materialsSpecs,
            num: item.inventoryNum
          });
        }
      });
      uniqueJsonArray(formState.materials[index].materialsSpecsData, 'text');
    };

    const handleChange = index => {
      formState.materials[index].materialsSpecs = undefined;
      formState.materials[index].supplierName = undefined;
      formState.materials[index].materialsNum = undefined;
    };

    const handleChange1 = index => {
      formState.materials[index].supplierName = undefined;
      formState.materials[index].materialsNum = undefined;
    }; // const handleFocusForNum = (val,index) => {
    //   /* formState.materials[index].materialsSpecsData.filter(item => item.value  === val).map(ixt => formState.materials[index].maxNum = ixt.num) */
    // }
    // //数量最大判断
    // const handleChangeForNum = (val,maxNum) => {
    //   if(val>maxNum){
    //     message.error(`库存不足！将自动改为最大库存值`)
    //   }
    // }


    const formRefForKug = ref();
    const isRequired = ref(false);

    const handlePassOrReject = async num => {
      const param = {
        applyId: props.applyDetailData.data.applyId,
        content: formState1.content ? formState1.content : null,
        state: num,
        belongProject: props.applyDetailData.data.belongProject,
        materials: []
      };

      if (num === 0) {
        if (!formState1.content) {
          _message.error('库管员确认说明不能为空！');

          return false;
        } else {
          // console.log(22222222,param)
          const res = await outboundUpdateToApproval(param);

          if (res.code == 200) {
            _message.success('操作成功！');

            context.emit('closeApplyVisible', {
              isClose: false,
              state: 2
            });
          } else {
            _message.error(res.message);
          }
        }
      } else {
        formRefForKug.value.validate().then(async () => {
          let isOk = true; //库管员审批且同意领用需要比对申请数量

          if (num == 1) {
            formState1.materials1.forEach((item, index) => {
              let snum = 0; // console.log(item)

              item.list.forEach(ixt => {
                snum += ixt.materialsNum;
                let snumc = 0;
                ixt.materialsLocationList.forEach(ixts => {
                  snumc += ixts.materialsNum;
                });

                if (snumc != ixt.materialsNum) {
                  _message.error("名称为" + item.materialsName + "、规格为" + item.materialsSpecs + "的材料领用数量与存放位置数量不一样！");

                  isOk = false;
                }
              });

              if (snum > formState.materials[index].materialsNum) {
                _message.error("名称为" + item.materialsName + "、规格为" + item.materialsSpecs + "的材料领用数量不能大于申请数量！");

                isOk = false;
              }
            });
          }

          if (!isOk) {
            return;
          } // 库管员审批且同意领用materials需传


          if (num == 1) {
            formState1.materials1.forEach((item, index) => {
              const obj = {
                outboundApplyId: props.applyDetailData.data.applyId,
                materialsName: item.materialsName,
                materialsSpecs: item.materialsSpecs,
                supplierNames: []
              };
              item.list.forEach((ixt, idx) => {
                obj.supplierNames.push({
                  supplierName: ixt.supplierName,
                  outboundNum: ixt.materialsNum,
                  materialsLocationList: ixt.materialsLocationList
                });
              });
              param.materials.push(obj);
            });
          } // console.log(1111111111,param)


          const res = await outboundUpdateToApproval(param);

          if (res.code == 200) {
            _message.success('操作成功！');

            context.emit('closeApplyVisible', {
              isClose: false,
              state: 2
            });
          } else {
            _message.error(res.message);
          }
        }).catch(error => {
          console.log('error', error);
        });
      }
    }; //设置是否可编辑


    const setDisabled = (val1, val2) => {
      if (val1) {
        return true;
      } else {
        if (val2) {
          return false;
        } else {
          return true;
        }
      }
    }; //库管添加数量


    const addMaterialNum = (index, id) => {
      formState.materials[index].list.push({
        supplierName: undefined,
        materialsNum: undefined,
        materialsLocationList: [{
          materialsId: id,
          methodType: 3,
          materialsNum: null,
          physicsLocation: null
        }]
      });
    }; //库管删除数量


    const deleteMaterialNum = (index, idx) => {
      formState.materials[index].list.splice(idx, 1);
    }; //库管修改供应商


    const kugArr = ref([]);
    const weiArr = ref([]);

    const handleFocusForSup1 = async (materialsName, materialsSpecs, index, supplierName) => {
      const param = {
        materialsName: materialsName,
        supplierName: null
      };
      let res = await selectBillByParam(param);
      console.log(res);

      try {
        kugArr.value = [];
        weiArr.value = [];

        if (res.data.length) {
          res.data.forEach(ixt => {
            if (ixt.materialsName == materialsName && ixt.materialsSpecs == materialsSpecs) {
              kugArr.value.push({
                value: ixt.supplierName,
                text: ixt.supplierName
              });
            }

            if (supplierName && ixt.materialsName == materialsName && ixt.materialsSpecs == materialsSpecs && ixt.supplierName == supplierName) {
              console.log(ixt);

              if (ixt.materialsLocationList.length) {
                ixt.materialsLocationList.forEach(ixts => {
                  weiArr.value.push({
                    value: ixts.physicsLocation,
                    text: ixts.physicsLocation
                  });
                });
              }
            }
          });
          uniqueJsonArray(kugArr.value, 'text');
          uniqueJsonArray(weiArr.value, 'text');
          console.log(weiArr.value);
        }
      } catch (error) {
        console.log(error);
      }
    }; //审批员审批相关


    const getCurrent = arr => {
      const newArr = arr.filter(item => item.status == 1);
      return newArr.length - 1;
    };

    const setRoleType = num => {
      switch (num) {
        case 0:
          return "申请人";

        case 1:
          return "专业负责人";

        case 2:
          return "库管员";

        case 3:
          return "其他审批人";
      }
    };

    const handlePassOrRejectForSP = num => {
      formRef.value.validate().then(async () => {
        if (!formState.content) {
          _message.error('审批意见不能为空！');

          return;
        }

        const param = {
          applyId: props.applyDetailData.data.applyId,
          content: formState.content,
          state: num,
          belongProject: props.applyDetailData.data.belongProject,
          materials: []
        };
        const res = await outboundUpdateToApproval(param);

        if (res.code == 200) {
          _message.success('审批成功！');

          context.emit('closeApplyVisible', {
            isClose: false,
            state: 2
          });
        } else {
          _message.error(res.message);
        }
      }).catch(error => {
        console.log('error', error);
      });
    }; //申请人签收
    //获取附件的url


    const getAdjuncts = val => {
      console.log('getAdjuncts', val);
      formState.signFileurl = val;
      return val;
    };

    const handleSign = () => {
      formRef.value.validate().then(async () => {
        if (!formState.signContent) {
          _message.error('签收说明不能为空！');

          return;
        }

        if (formState.signFileurl.length == 0) {
          _message.error('签收凭证不能为空！');

          return;
        }

        const param = {
          applyId: props.applyDetailData.data.applyId,
          signContent: formState.signContent,
          signFileurl: JSON.stringify(formState.signFileurl)
        };
        const res = await insertReviewing(param);

        if (res.code == 200) {
          _message.success('签收成功！');

          context.emit('closeApplyVisible', {
            isClose: false,
            state: 2
          });
        } else {
          _message.error(res.message);
        }
      }).catch(error => {
        console.log('error', error);
      });
    }; //申请人确认


    const handlePassOrRejectForSQR = async num => {
      const param = {
        applyId: props.applyDetailData.data.applyId,
        content: formState1.content ? formState1.content : null,
        state: num,
        belongProject: props.applyDetailData.data.belongProject,
        materials: []
      };
      const res = await outboundUpdateToApproval(param);

      if (res.code == 200) {
        _message.success('确认成功！');

        context.emit('closeApplyVisible', {
          isClose: false,
          state: 2
        });
      } else {
        _message.error('确认失败！');
      }
    }; //申请人确认归还


    const handleReturn = num => {
      formRef.value.validate().then(async () => {
        const param = {
          applyId: props.applyDetailData.data.applyId,
          belongProject: props.applyDetailData.data.belongProject,
          returnName: formState.returnName,
          returnPhone: formState.returnPhone
        };
        console.log(param);
        const res = await insertReturn(param);

        if (res.code == 200) {
          _message.success('归还成功！');

          context.emit('closeApplyVisible', {
            isClose: false,
            state: 2
          });
        } else {
          _message.error('归还失败！');
        }
      });
    }; //库管员归还确认


    const getAdjuncts1 = val => {
      console.log('getAdjuncts', val);
      formState2.returnFileurl = val;
    };

    const formRefForGui = ref();

    const handleReturnForKu = () => {
      formRefForGui.value.validate().then(async () => {
        let isOk = true;
        formState2.materials1.forEach((item, index) => {
          item.supplierNames.forEach((ixt, inx) => {
            // console.log(ixt.outboundNum,formState.confirmMaterials[index].supplierNames[inx].outboundNum)
            if (ixt.outboundNum > formState.confirmMaterials[index].supplierNames[inx].outboundNum) {
              _message.error("名称为" + item.materialsName + "、规格为" + item.materialsSpecs + "、供应商为" + ixt.supplierName + "的材料归还数量不能大于领用数量！");

              isOk = false;
            }

            let snum = 0;
            ixt.materialsLocationList.forEach(ixts => {
              snum += ixts.materialsNum;
            });

            if (snum != ixt.outboundNum) {
              _message.error("名称为" + item.materialsName + "、规格为" + item.materialsSpecs + "、供应商为" + ixt.supplierName + "的材料归还数量与存放位置数量不一样！");

              isOk = false;
            }
          });
        });

        if (!isOk) {
          return;
        }

        const param = {
          applyId: props.applyDetailData.data.applyId,
          belongProject: props.applyDetailData.data.belongProject,
          materials: formState2.materials1,
          returnFileurl: JSON.stringify(formState2.returnFileurl)
        }; // console.log(param)

        const res = await insertReturnConfirm(param);

        if (res.code == 200) {
          _message.success('确认归还成功！');

          context.emit('closeApplyVisible', {
            isClose: false,
            state: 2
          });
        } else {
          _message.error('确认归还失败！');
        }
      }).catch(error => {
        console.log('error', error);
      });
    }; //打印


    const handlePrint = () => {
      let div = document.createElement('div'); // div.setAttribute('id','printCons');

      div.appendChild(setTable(`出库申请材料清单--${formState.theme}`, setTableData(formState.materials)));

      if (formState.confirmMaterials.length) {
        div.appendChild(setTable1(`出库库管确认材料清单--${formState.theme}`, setTableData1(formState.confirmMaterials)));
      }

      if (formState.returnMaterials.length) {
        div.appendChild(setTable1(`归还材料清单--${formState.theme}`, setTableData1(formState.returnMaterials)));
      }

      printJS({
        printable: div,
        type: 'html',
        style: `
          table {
            border:2px solid #ccc;
            border-bottom: none;
            border-left: none;
            border-collapse: separate;
            border-spacing: 0;
            width: 100%;
          }
          th { 
            border-bottom: 2px solid #ccc;
            width: 25%;
            text-align: left;
            border-left: 2px solid #ccc;
          }
          td { 
            border-bottom: 2px solid #ccc;
            border-left: 2px solid #ccc;
          }
          `,
        // gridHeaderStyle:'font-size:12px; padding:3px; border:1px solid; font-weight: 100; text-align:left;',
        // gridStyle:'font-size:12px; padding:3px; border:1px solid; text-align:left;',
        // repeatTableHeader: true,
        // scanStyles:true,
        targetStyles: ['*'],
        ignoreElements: ['no-print', 'bc', 'gb']
      });
    }; //下载


    const handleDownExcel = () => {
      require.ensure([], () => {
        const {
          export_json_to_excel1
        } = require("@/assets/excel/Export2Excel");

        const tHeader = ["材料名称", "规格", "供应商", "数量"]; // 导出的excel表头名信息

        const titleNameArr = [`出库材料清单--${formState.theme}`]; // 导出的excel表头名信息

        const filterVal = ["materialsName", "materialsSpecs", "supplierName", "materialsNum"]; // 导出的excel表头字段名，需要导出表格字段名

        const list = setTableData(formState.materials);
        const arr = [];
        const data = formatJson(filterVal, list);
        const allData = [data];

        if (formState.confirmMaterials.length) {
          titleNameArr.push(`出库库管确认材料清单--${formState.theme}`);
          let data1 = formatJson(filterVal, setTableData1(formState.confirmMaterials));
          allData.push(data1);
        }

        if (formState.returnMaterials.length) {
          titleNameArr.push(`归还材料清单--${formState.theme}`);
          let data1 = formatJson(filterVal, setTableData1(formState.returnMaterials));
          allData.push(data1);
        } // console.log(111111,allData)


        export_json_to_excel1(tHeader, titleNameArr, allData, `出库材料清单--${formState.theme}`); // 导出的表格名称，根据需要自己命名
      });
    }; // 数据生成表格


    const setTable = (val, data) => {
      let div = document.createElement('div');
      let table = document.createElement('table');
      let h2 = document.createElement('h2');
      h2.innerHTML = val;
      let tr1 = document.createElement('tr');
      table.appendChild(tr1);
      tr1.innerHTML = '<th>材料名称</th><th>规格</th><th>供应商</th><th>数量</th>';

      for (let i = 0; i < data.length; i++) {
        let tr = document.createElement('tr');
        table.appendChild(tr);

        for (let key in data[i]) {
          let td = document.createElement('td');
          td.textContent = data[i][key];
          tr.appendChild(td);
        }

        table.appendChild(tr);
      }

      div.appendChild(h2);
      div.appendChild(table);
      return div;
    }; // 数据生成合并的表格


    const setTable1 = (val, arr) => {
      let data = JSON.parse(JSON.stringify(arr));
      let div = document.createElement('div');
      let h2 = document.createElement('h2');
      h2.innerHTML = val;
      let myDataT,
          rowcell = [];
      myDataT = document.createElement('table');
      let tr1 = document.createElement('tr');
      myDataT.appendChild(tr1);
      tr1.innerHTML = '<th>材料名称</th><th>规格</th><th>供应商</th><th>数量</th>'; // debugger

      for (let i = 0; i < data.length; i++) {
        //遍历表格中每项
        let atr = myDataT.insertRow(); //动态添加行

        for (let key in data[i]) {
          let arr = data[i].supplierName.split("-");
          data[i].supplierName = arr[0]; //遍历某项中的所有数据

          if (data[i][key] === "") {
            //如果值为空，表示需要合并
            rowcell[key].rowSpan++; //使用rowspan
          } else {
            rowcell[key] = atr.insertCell(); //否则正常添加行

            rowcell[key].innerText = data[i][key]; //显示行内容
          }
        }
      }

      div.appendChild(h2);
      div.appendChild(myDataT);
      return div;
    }; //表格数据转换


    const setTableData = val => {
      let data = [];

      for (let i = 0; i < val.length; i++) {
        data.push({
          materialsName: val[i].materialsName,
          materialsSpecs: val[i].materialsSpecs,
          supplierName: val[i].supplierName ? val[i].supplierName : "",
          materialsNum: val[i].materialsNum || val[i].warehouseNum
        });
      }

      return data;
    };

    const setTableData1 = val => {
      let data = [];

      for (let i = 0; i < val.length; i++) {
        let num = val[i].supplierNames.length;
        val[i].supplierNames.forEach((ixt, index) => {
          let obj = {
            materialsName: index == 0 ? val[i].materialsName : "",
            materialsSpecs: index == 0 ? val[i].materialsSpecs : "",
            supplierName: `${ixt.supplierName}-${i}-${num}`,
            materialsNum: ixt.outboundNum
          };
          data.push(obj);
        });
      }

      return data;
    }; //格式转换


    const formatJson = (filterVal, jsonData) => {
      return jsonData.map(v => filterVal.map(j => v[j]));
    }; //设置步骤条样式


    const setStatus = val => {
      const isError = val.some(item => item.isReject === 1);

      if (isError) {
        return 'error';
      }
    }; //出库信息--存放位置
    //添加存放位置


    const addMaterialsLocation = (index, idx, id) => {
      formState.materials[index].list[idx].materialsLocationList.push({
        materialsId: id,
        methodType: 3,
        materialsNum: null,
        physicsLocation: null
      });
    }; //删除存放位置


    const deleteMaterialsLocation = (index, idx, idxs) => {
      formState.materials[index].list[idx].materialsLocationList.splice(idxs, 1);
    }; // 归还信息位置


    const guiWeiArr = ref([]);

    const handleFocusForGuiWei = async () => {
      const param = {
        physicsLocationLike: null
      };
      let res = await selectLocationByParam(param);

      try {
        guiWeiArr.value = [];

        if (res.data.length) {
          res.data.forEach(ixt => {
            guiWeiArr.value.push({
              value: ixt.physicsLocation,
              text: ixt.physicsLocation
            });
          });
          uniqueJsonArray(guiWeiArr.value, 'text');
        }
      } catch (error) {
        console.log(error);
      }
    }; //添加归还信息位置


    const addMaterialsLocation1 = (index, idx, id) => {
      formState2.materials1[index].supplierNames[idx].materialsLocationList.push({
        materialsId: id,
        methodType: 4,
        materialsNum: null,
        physicsLocation: null
      });
    }; //删除归还信息位置


    const deleteMaterialsLocation1 = (index, idx, idxs) => {
      formState2.materials1[index].supplierNames[idx].materialsLocationList.splice(idxs, 1);
    }; //所属项目查询


    const projectOptions = ref([]);

    const getApprovalItem = async () => {
      const param = {
        type: 2,
        belongProject: Number(Cookies.get("belongProject"))
      };
      let res = await selectApprovalItem(param);

      if (res.code === 200) {
        projectOptions.value = [];

        if (res.data.approvalItems && res.data.approvalItems.length) {
          res.data.approvalItems.forEach(item => {
            projectOptions.value.push({
              value: item.id,
              label: item.itemName
            });
          });
        }
      } else {
        _message.error(res.message);
      }
    };

    onMounted(() => {
      getApprovalItem();
    });
    return {
      closeApplyVisible,
      formRef,
      formState,
      rules,
      addMaterialInformation,
      deleteMaterialInformation,
      handleOk,
      loading,
      disabledDate,
      handleSearch,
      handleChange,
      handleFocusForSp,
      handleChange1,
      handleFocusForSup,
      isDisabledForCl,
      isDisabledForSp,
      handlePassOrReject,
      formState1,
      setDisabled,
      addMaterialNum,
      deleteMaterialNum,
      handleFocusForSup1,
      kugArr,
      formRefForKug,
      // isRequired,
      getCurrent,
      setRoleType,
      handlePassOrRejectForSP,
      getAdjuncts,
      handleSign,
      handlePassOrRejectForSQR,
      isDisabledForKu,
      handleReturn,
      formState2,
      handleReturnForKu,
      getAdjuncts1,
      formRefForGui,
      handlePrint,
      handleDownExcel,
      setStatus,
      addMaterialsLocation,
      deleteMaterialsLocation,
      weiArr,
      checkName,
      checkPhone,
      handleFocusForGuiWei,
      guiWeiArr,
      addMaterialsLocation1,
      deleteMaterialsLocation1,
      projectOptions
    };
  }

});