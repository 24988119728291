/*
 * 材料相关接口列表
 */

import axios from '@/utils/http'; // 导入http中创建的axios实例

//查询审核和流转时间
export const selectWarntime = (param) => {
  return axios.post(`/personnelapi/hyWarntime/selectWarntime`, param);
}
//修改审核和流转时间
export const updateWarntime = (param) => {
  return axios.post(`/personnelapi/hyWarntime/updateWarntime`, param);
}
//查询管理岗所有人员
export const selectJobCascade = (param) => {
  return axios.post(`/personnelapi/hyWarntime/selectJobCascade`, param);
}
//查询审核责任人
export const selectVerifierSetting = (param) => {
  return axios.post(`/personnelapi/hyApproachSetting/selectVerifierSetting`, param);
}
//查询审核责任人
export const insertApproachApply = (param) => {
  return axios.post(`/wlapi/hyApproachApply/insertApproachApply`, param);
}
//专业信息查询
export const selectProfessional = (param) => {
  return axios.post(`/personnelapi/hyProfessional/selectProfessional`, param);
}
//设置审核责任人
export const updateVerifierSetting = (param) => {
  return axios.post(`/personnelapi/hyApproachSetting/updateVerifierSetting`, param);
}
//材料名查询
export const selectMaterialsByName = (param) => {
  return axios.get(`/wlapi/hyMaterialsName/selectMaterialsByName?name=${param}`); 
}
//供应商名称查询
export const selectSupplierByName = (param) => {
  return axios.get(`/wlapi/hySupplier/selectSupplierByName?name=${param}`); 
}
//进场申请列表
export const selectApplyByPagination = (param) => {
  return axios.post(`/wlapi/hyApproachApply/selectApplyByPagination`, param); 
}
//进场详情
export const selectApplyById = (param) => {
  return axios.get(`/wlapi/hyApproachApply/selectApplyById?id=${param}`); 
}
//审批
export const updateToApproval = (param) => {
  return axios.post(`/wlapi/hyApproachApply/updateToApproval`,param); 
}
//修改
export const updateApply = (param) => {
  return axios.post(`/wlapi/hyApproachApply/updateApply`,param); 
}
//出库申请
export const insertOutboundApply = (param) => {
  return axios.post(`/personnelapi/hyOutboundApply/insertOutboundApply`,param); 
}

//材料名字清单
export const selectBillByParam = (param) => {
  return axios.post(`/wlapi/hyMaterialsBill/selectBillByParam`,param); 
}
//出库申请列表
export const selectOutboundApplyByPagination = (param) => {
  return axios.post(`/personnelapi/hyOutboundApply/selectOutboundApplyByPagination`,param); 
}
//出库申请列表详情
export const outboundSelectApplyById = (param) => {
  return axios.get(`/personnelapi/hyOutboundApply/selectApplyById?id=${param}`); 
}
//出库审核
export const outboundUpdateToApproval = (param) => {
  return axios.post(`/personnelapi/hyOutboundApply/updateToApproval`,param); 
}
//出库申请人复核
export const insertReviewing = (param) => {
  return axios.post(`/personnelapi/hyOutboundApply/insertReviewing`,param); 
}
//出库申请人修改
export const updateOutboundApply = (param) => {
  return axios.post(`/personnelapi/hyOutboundApply/updateOutboundApply`,param); 
}
//出库申请人归还
export const insertReturn = (param) => {
  return axios.post(`/personnelapi/hyOutboundApply/insertReturn`,param); 
}
//出库库管归还
export const insertReturnConfirm = (param) => {
  return axios.post(`/personnelapi/hyOutboundApply/insertReturnConfirm`,param); 
}

//入库列表
export const entrySelectApplyByPagination = (param) => {
  return axios.post(`/wlapi/hyWarehouseApply/selectApplyByPagination`,param); 
}
//入库列表详情
export const entrySelectApplyById = (param) => {
  return axios.get(`/wlapi/hyWarehouseApply/selectApplyById?id=${param}`); 
}
//入库
export const updateWarehouse = (param) => {
  return axios.post(`/wlapi/hyWarehouseApply/updateWarehouse`,param); 
}
//退场申请
export const insertExitApply = (param) => {
  return axios.post(`/personnelapi/hyExitApply/insertExitApply`,param); 
}
//退场列表
export const selectExitApplyByPagination = (param) => {
  return axios.post(`/personnelapi/hyExitApply/selectExitApplyByPagination`,param); 
}
//退场审核
export const exitUpdateToApproval = (param) => {
  return axios.post(`/personnelapi/hyExitApply/updateToApproval`,param); 
}
//退场详情
export const exitSelectApplyById = (param) => {
  return axios.get(`/personnelapi/hyExitApply/selectApplyById?id=${param}`); 
}
//退场确认
export const exitInsertReviewing = (param) => {
  return axios.post(`/personnelapi/hyExitApply/insertReviewing`,param); 
}
//修改退场
export const exitUpdateExitApply = (param) => {
  return axios.post(`/personnelapi/hyExitApply/updateExitApply`,param); 
}

//材料列表
export const selectBillByPagination = (param) => {
  return axios.post(`/wlapi/hyMaterialsBill/selectBillByPagination`,param); 
}
//材料列表
export const selectAllBill = () => {
  return axios.post(`/wlapi/hyMaterialsBill/selectAllBill`); 
}

//查询模块项目
export const selectApprovalItem = (param) => {
  return axios.post(`/personnelapi/hyApproachSetting/selectApprovalItem`, param);
}

//查询材料位置
export const selectLocationByParam = (param) => {
  return axios.post(`/wlapi/hyMaterialsBill/selectLocationByParam`, param);
}
//取消转移
export const setTemporaryTransferState = (param) => {
  return axios.post(`/personnelapi/knTemporaryTransfer/setTemporaryTransferState`, param);
}
//设置临时审批人
export const setTemporaryTransfer = (param) => {
  return axios.post(`/personnelapi/knTemporaryTransfer/setTemporaryTransfer`, param);
}
//查询模块下的所有审批人
export const selectApproachByModular = (param) => {
  return axios.post(`/personnelapi/hyApproachSetting/selectApproachByModular`, param);
}
//查询审批流程线下的所有专业
export const selectProfessionalByItem = (param) => {
  return axios.post(`/personnelapi/hyProfessional/selectProfessionalByItem`, param);
}
//查询模块下的所有专业
export const selectProfessionalByModular = (param) => {
  return axios.post(`/personnelapi/hyProfessional/selectProfessionalByModular`, param);
}
//删除审批流程线
export const deleteVerifierSetting = (param) => {
  return axios.post(`/personnelapi/hyApproachSetting/deleteVerifierSetting`, param);
}