import "ant-design-vue/es/message/style/css";
import _message from "ant-design-vue/es/message";
import { UploadOutlined } from '@ant-design/icons-vue';
import { defineComponent, ref, computed, watch } from 'vue';
import Cookies from "js-cookie";
export default defineComponent({
  components: {
    UploadOutlined
  },
  props: ['listType', 'fileList', 'type', 'isOne'],
  // 接收父组件的方法
  emits: ['imgUrls'],

  setup(props, context) {
    const fileList1 = ref([]);
    const imageUrl = ref('');
    const loading = ref(false);

    const handleChange = info => {
      if (info.file.status === 'uploading') {
        loading.value = true;
        return;
      }

      if (info.file.status === 'done') {
        if (info.file.response.code == 200) {
          let resFileList = [...info.fileList];

          if (props.isOne) {
            //是否只能上传1个文件
            resFileList = resFileList.slice(-1);
          }

          loading.value = false;
          const urls = [];
          resFileList.forEach(item => {
            if (item.response) {
              urls.push(item.response.data);
            } else {
              urls.push(item.name);
            }
          });
          context.emit('imgUrls', urls);
        } else {
          _message.error(info.file.response.message);
        }
      } //删除回调


      if (info.file.status === "removed") {
        const urls = [];
        info.fileList.forEach(item => {
          if (item.response) {
            urls.push(item.response.data);
          } else {
            urls.push(item.name);
          }
        });
        context.emit('imgUrls', urls);
      }

      if (info.file.status === 'error') {
        loading.value = false;

        _message.error('上传失败');
      }
    };

    const beforeUpload = (file, fileList) => {
      // console.log(props.listType)
      return new Promise((resolve, reject) => {
        if (props.listType === 'picture') {
          const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';

          if (!isJpgOrPng) {
            _message.error('只能上传JPG/PNG文件!');

            return reject(false);
          }

          const isLt2M = file.size / 1024 / 1024 < 2;

          if (!isJpgOrPng && !isLt2M) {
            _message.error('图片大小不能大于2MB!');

            return reject(false);
          }

          return resolve(true);
        } else {
          // const isExelsOrPdf = file.type === 'application/vnd.ms-excel' || file.type ==='application/pdf' || file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || file.type === 'application/vnd.ms-excel application/x-excel'
          // if (!isExelsOrPdf) {
          //   message.error('只能上传.xls, .xlsx, .pdf文件!');
          //   return reject(false)
          // }
          if (props.type) {
            if (props.type === 'xls') {
              const isExelsOrPdf = file.type === 'application/vnd.ms-excel' || file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || file.type === 'application/vnd.ms-excel application/x-excel';

              if (!isExelsOrPdf) {
                _message.error('只能上传.xls, .xlsx文件!');

                return reject(false);
              }

              return resolve(true);
            }
          } else {
            return resolve(true);
          }
        }
      });
    };

    const uploadHeaders = computed(() => ({
      'token': Cookies.get('token'),
      'belongProject': Cookies.get('belongProject')
    }));
    watch(() => props.fileList, () => {
      // console.log(1111111,props.fileList)
      if (props.fileList.length) {
        fileList1.value = [];
        props.fileList.forEach((item, index) => {
          fileList1.value.push({
            uid: index,
            name: item,
            status: 'done',
            url: `/personnelapi/image/${item}`
          });
        });
      } else {
        fileList1.value = [];
      } // console.log('fileList1', fileList1.value)
      // console.log('fileList', props.fileList)

    }, {
      immediate: true
    });
    return {
      fileList1,
      beforeUpload,
      handleChange,
      imageUrl,
      loading,
      uploadHeaders
    };
  }

});