import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_upload_outlined = _resolveComponent("upload-outlined");

  const _component_a_button = _resolveComponent("a-button");

  const _component_a_upload = _resolveComponent("a-upload");

  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_a_upload, {
    action: "/personnelapi/HyVisitors/uploadBatch",
    "list-type": _ctx.listType,
    "file-list": _ctx.fileList1,
    "onUpdate:file-list": _cache[0] || (_cache[0] = $event => _ctx.fileList1 = $event),
    class: "upload-list-inline",
    name: "files",
    "before-upload": _ctx.beforeUpload,
    onChange: _ctx.handleChange,
    data: {
      type: 3
    },
    headers: _ctx.uploadHeaders
  }, {
    default: _withCtx(() => [_createVNode(_component_a_button, null, {
      default: _withCtx(() => [_createVNode(_component_upload_outlined), _createTextVNode(" " + _toDisplayString(_ctx.listType === 'picture' ? '上传图片' : '上传附件'), 1)]),
      _: 1
    })]),
    _: 1
  }, 8, ["list-type", "file-list", "before-upload", "onChange", "headers"])]);
}