function getFieldRules() {
  const checkName = async (rules, value) => {
    let nameReg = /^[\u4E00-\u9FA5]{2,8}$/;
    if (!nameReg.test(value)) {
      return Promise.reject('请输入正确姓名');
    } else {
      return Promise.resolve();
    }
  };
  const checkPhone = async (rules, value) => {
    let phoneReg = /(^1[3|4|5|6|7|8|9]\d{9}$)|(^09\d{8}$)/;
    if (!phoneReg.test(value)) {
      return Promise.reject('请输入正确手机号');
    } else {
      return Promise.resolve();
    }
  };
  const checkCard = async (rules, value) => {
    let cardReg = /(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)|(^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{2}$)/;
    if (!cardReg.test(value)) {
      return Promise.reject('请输入正确身份证号');
    } else {
      return Promise.resolve();
    }
  }
  const validatePass = async (rule, value) => {
    if (value === '') {
      return Promise.reject('不能为空');
    } else {
      return Promise.resolve();
    }
  };
  const validateTime = async (rule, value) => {
    console.log(value)
    if (!value || value.length ==0) {
      return Promise.reject('请假时间不能为空');
    } else {
      return Promise.resolve();
    }
  };
  const checkJob = async (rule, value) => {
    if (value == undefined || value.length === 0) {
      switch (rule.field) {
        case 'salary':
          return Promise.reject('薪资不能为空');
        case 'jobNo':
          return Promise.reject('人员岗位不能为空');
        case 'labelIds':
          return Promise.reject('标签不能为空');
      }
    } else {
      return Promise.resolve();
    }
  };
  const checkPositiveNumber = async (rules, value) => {
    let NumberReg = /(^(([0-9]+[\.]?[0-9]+)|[1-9])$)/;
    if (!NumberReg.test(value)) {
      return Promise.reject('请输入正数');
    } else {
      return Promise.resolve();
    }
  }
  const signFileurl = async(rules, value) => {
    // console.log('wwwwwwwwww',value)
    if (!value.length) {
      return Promise.reject('上传附件不能为空！');
    } else {
      return Promise.resolve();
    }
  }
  const money = async(rules, value) => {
    // console.log('wwwwwwwwww',value)
    if (!value) {
      if(value === 0){
        return Promise.reject('金额不能等于0');
      }
      return Promise.reject('金额不能为空');
    } else {
      return Promise.resolve();
    }
  }
  const email = async(rules, value) => {
    let emailReg = /^([a-zA-Z0-9]+[_|_|\-|.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|_|.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,6}$/;
    if (!emailReg.test(value)) {
      return Promise.reject('请输入正确邮箱');
    } else {
      return Promise.resolve();
    }
  }
  const userName = async (rules, value) => {
    let cardReg = /^[a-zA-Z0-9_]{6,14}$/;
    if (!cardReg.test(value)) {
      return Promise.reject('支持英文大小写及数字和下划线，长度在 6 到 14 个字符');
    } else {
      return Promise.resolve();
    }
  }
  const userPassword = async (rules, value) => {
    let cardReg = /^[a-zA-Z0-9@#$%^&*~_?!]{6,14}$/;
    if (!cardReg.test(value)) {
      return Promise.reject('支持英文大小写数字和特殊字符@#$%^&*~_?!，长度在 6 到 14 个字符');
    } else {
      return Promise.resolve();
    }
  }
  return {
    checkName,
    checkPhone,
    checkCard,
    validatePass,
    checkJob,
    checkPositiveNumber,
    signFileurl,
    money,
    email,
    validateTime,
    userName,
    userPassword
  }
}
export default getFieldRules